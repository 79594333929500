<i18n>
{
  "de": {
    "pageTitle": "Reduktionsziele",
    "settings": "Einstellungen"
    }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>
    </template>

    <template #default>
      <TargetsChart v-if="portfolio.targets" :portfolio="portfolio" />
      <EditTargets v-if="portfolio.targets" :portfolio="portfolio" :value="portfolio.targets" class="targets-list" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import EditTargets from '@/components/settings/EditTargets.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import TargetsChart from './TargetsChart.vue'

export default {
  name: 'settingsTargets',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    EditTargets,
    PageTitle,
    TargetsChart,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.targets-page .targets-list {
  max-width: 800px;
  margin-top: var(--spacing-m);
}
</style>
