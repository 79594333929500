<i18n>
{
  "de": {
    "targetsTitle": "Ziele",
    "yearTitle": "Jahr",
    "s12eTargetTitle": "Ziel Scope 1-2 Emissionen",
    "s123eTargetTitle": "Ziel Scope 1-3 Emissionen",
    "eeTargetTitle": "Ziel Endenergie",
    "peTargetTitle": "Ziel Primärenergie",
    "noTargets": "Dieses Portfolio enthält keine Ziele",
    "addTargetButton": "Ziel hinzufügen"
  }
}
</i18n>

<template>
  <div class="c-edit-targets">
    <DetailList v-if="hasTargets || target" has-header>
      <template #header>
        <Box>{{ $t('yearTitle') }}</Box>
        <HeaderEntry :title="$t('s12eTargetTitle')" unit="kg CO₂e/m²" class="align-right" />
        <HeaderEntry :title="$t('s123eTargetTitle')" unit="kg CO₂e/m²" class="align-right" />
        <HeaderEntry :title="$t('eeTargetTitle')" unit="kWh/m²" class="align-right" />
        <HeaderEntry :title="$t('peTargetTitle')" unit="kWh/m²" class="align-right" />
        <Box />
      </template>

      <EditTargetRow
        v-for="target in targets"
        :key="target.id"
        v-model="target.item"
        :allow-edit="getPortfolioPermission('EDIT_TARGETS')"
        :other-targets="getOtherTargets(target.item)"
      />
      <EditTargetRow v-if="target" v-model="target" :other-targets="allTargets" :edit="true" />
    </DetailList>

    <p v-else>{{ $t('noTargets') }}</p>

    <ButtonWrapper>
      <Button
        v-if="!target && getPortfolioPermission('EDIT_TARGETS')"
        icon="plus"
        :text="$t('addTargetButton')"
        @click="onAddTarget"
      />
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Box from '@/components/cui/layout/Box.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import Button from '@/components/cui/inputs/Button.vue'
import EditTargetRow from '@/components/settings/EditTargetRow.vue'

export default {
  components: {
    Box,
    ButtonWrapper,
    EditTargetRow,
    DetailList,
    HeaderEntry,
    Button,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      targets: null,
      target: null,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    hasTargets() {
      return Boolean(this.targets) && this.targets.length > 0
    },

    allTargets() {
      return (this.targets || []).map(({ item }) => item)
    },
  },

  watch: {
    value(value) {
      this.updateTargets(value)
    },
  },

  created() {
    this.updateTargets(this.value)
  },

  methods: {
    updateTargets(targets) {
      this.targets = (targets || []).map((item) => ({ item })).sort((a, b) => a.item.year - b.item.year)
    },

    getOtherTargets(target) {
      return this.allTargets.filter((t) => t.id !== target.id)
    },

    onAddTarget() {
      const year = this.hasTargets
        ? this.allTargets[this.allTargets.length - 1].year + 10
        : new Date().getFullYear() + 10
      this.target = {
        portfolio_id: this.portfolio.id,
        year,
        s12e_total: null,
        s123e_total: null,
        ee_total: null,
        pe_total: null,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-targets {
  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .detail-list {
    margin-bottom: var(--spacing-m);
  }
}
</style>
